import React, { Component } from 'react';
import { Button, InputGroup, InputLabel, TextField } from '@btag/bt-ui-library';

class ResetView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: ''
        }
    }

    _handleResetPassword = (e) => {
        if (e.key === 'Enter') {
            this._submitResetPassword();
        }
    }
    
    _submitResetPassword = () => {
        this.props.handleResetPassword(this.state.email);
    }

    _handleChangeText = (e) => {
        this.setState({ email: e.target.value })
    }

    render() {
        const { errorMsgResetRequest, errorMsg, handleCancel, staffbaseBranding } = this.props;
        return (<div className="reset-view">
            {
                staffbaseBranding === true &&
                <div className="login-header-sb">
                <img src="assets/images/Staffbase/staffbase_wordmark.svg" alt="Staffbase" />
            </div>
            }
            <h3 className="login-header-text">
                Forgot your password?
            </h3>
            <div className="login-text">
                Enter your email address below and we will email you the instructions on how you can gain access back into your Bananatag account.
            </div>
            { errorMsgResetRequest ?
                <div className="error-grp">
                    <p className="error" dangerouslySetInnerHTML={{ __html: errorMsgResetRequest }} />
                </div>
                : null }
            <InputGroup className="login-email reset" errorMsg={errorMsg}>
                <InputLabel>Email Address</InputLabel>
                <TextField placeholder="example@email.com" onKeyUp={this._handleResetPassword} value={this.state.email} onChange={this._handleChangeText} />
            </InputGroup>
            <div className="btn-row">
                <Button className="cancel-btn" type="quiet" onClick={handleCancel}>Cancel</Button>
                <Button className="reset-password" type="solid"
                        onClick={this._submitResetPassword}>
                    Reset
                </Button>
            </div>
        </div>);
    }
}

export default ResetView;