import React, { useState } from 'react';
import { Button, CheckboxGroup, Checkbox } from "@btag/bt-ui-library";
import config from '../config';

const StaffbaseRedirect = (props) => {
    const { email } = props;

    const [skipRedirectWarning, setRedirectPreference] = useState(window.localStorage.getItem('sbRedirect') == true)

    const handleChange = (e) => {
        setRedirectPreference(e)
        window.localStorage.setItem('sbRedirect', e)
    }

    const goToRedirect = () => {
        return window.location = `${config.apiPublicHost}/sso/staffbase_redirect?email=${encodeURIComponent(email)}`;
    }

    return (
        <>
            <div className="sb-redirect-modal-bg"></div>
            <div className="sb-redirect-modal">
                <div className="main-wrapper">
                    <img src="assets/images/Staffbase/bananatag-soon-to-be-logo.svg" alt="Bananatag. Soon to be Staffbase." />
                    <div className="sb-redirect-modal-text">
                        <p data-test-id="sb-redirect-message" >Things are changing as we combine companies: please complete your login with the Experience Studio credentials.</p>
                        <p>We will redirect you to your Bananatag Dashboard right afterwards.</p>
                        <CheckboxGroup className="redirect-toggle">
                            <Checkbox
                                data-test-id="redirect-preference"
                                label="Don't show this again on this browser"
                                onChange={(e) => handleChange(e)}
                                checked={skipRedirectWarning}
                            />
                        </CheckboxGroup>
                        <Button
                            data-test-id="sb-redirect-button"
                            type="solid"
                            onClick={() => goToRedirect()}
                        >
                            Continue to Staffbase
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StaffbaseRedirect;
