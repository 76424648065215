import React, { Component } from 'react';
import { Button, InputGroup, InputLabel, TextField } from '@btag/bt-ui-library';
import Loader from './loader';
import StaffbaseRedirect from './StaffbaseRedirect';

class LoginView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            email: '',
            password: ''
        };


    }

    _handleEmailEnter = e => {
        if (e.key === 'Enter') {
            this._handleEmailSubmit();
        }
    };

    _handleEmailSubmit = () => {
        this.props.handleEmailSubmit(this.state.email);
    };

    _handleChangeEmail = e => {
        this.setState({ email: e.target.value.trim() });
    };

    _handleSubmit = e => {
        if (!this.props.lockout && e.key === 'Enter') {
            this._login();
        }
    };

    _login = () => {
        this.props.login(this.props.email, this.state.password);
    };

    _handleChangePassword = e => {
        this.setState({ password: e.target.value });
    };

    render() {
        const {
            errorMsgLogin,
            resetEmail,
            errorMsg,
            auth,
            handleForgotPassword,
            isAuthenticating,
            isRedirecting,
            lockout,
            email,
            emailEntered,
            loginProxyMsg,
            ssoEnabled,
            isStaffbaseAccount,
            staffbaseBranding
        } = this.props;


        if (isAuthenticating && !isStaffbaseAccount) {
            return <Loader />;
        }

        if (isRedirecting && isStaffbaseAccount) {
            return <StaffbaseRedirect email={email} />
        }

        return (
            <div className="login-view">
                {!emailEntered ? (
                    <div className="login-header-container">
                        {
                            staffbaseBranding === true &&
                            <div className="login-header-sb">
                                <img src="assets/images/Staffbase/staffbase_wordmark.svg" alt="Staffbase" />
                            </div>
                        }
                        {
                            staffbaseBranding === false &&
                            <h3 className="login-header-text">Sign In</h3>
                        }
                    </div>
                ) : (
                    <div className="login-header-container">
                        {
                            staffbaseBranding === true &&
                            <div className="login-header-sb">
                                <img src="assets/images/Staffbase/staffbase_wordmark.svg" alt="Staffbase" />
                            </div>
                        }
                        {
                            staffbaseBranding === false &&
                            <h3 className="login-header-text">Welcome</h3>
                        }
                        <div className="email-group">
                            <p className="login-header-email">{email}</p>
                            <Button
                                className="change-email"
                                type="no-effect"
                                onClick={resetEmail}
                            >
                                Change
                            </Button>
                        </div>
                    </div>
                )}
                {loginProxyMsg && (
                    <div className="login-proxy-msg-grp">
                        <p>{loginProxyMsg}</p>
                    </div>
                )}
                {errorMsgLogin && (
                    <div className="error-grp">
                        <p className="error" dangerouslySetInnerHTML={{__html: `${errorMsgLogin}`}} />
                    </div>
                )}

                {!emailEntered ? (
                    <div className="email-section">
                        <InputGroup className="login-email" errorMsg={errorMsg}>
                            <InputLabel>Email Address</InputLabel>
                            <TextField
                                autoFocus
                                autoComplete="email"
                                key="email"
                                placeholder="example@email.com"
                                onKeyUp={this._handleEmailEnter}
                                value={this.state.email}
                                onChange={this._handleChangeEmail}
                            />
                        </InputGroup>
                        <Button
                            className="next-btn"
                            type="solid"
                            onClick={this._handleEmailSubmit}
                        >
                            Next
                        </Button>
                    </div>
                ) : (
                    <div className="password-section">
                        <InputGroup errorMsg={errorMsg}>
                            <InputLabel>Password</InputLabel>
                            <TextField
                                autoFocus
                                autoComplete="password"
                                key="password"
                                type="password"
                                onKeyUp={this._handleSubmit}
                                value={this.state.password}
                                onChange={this._handleChangePassword}
                                placeholder="Enter your password"
                            />

                        </InputGroup>
                        {!loginProxyMsg && (
                            <Button
                                className="reset-pw-btn"
                                type="quiet"
                                onClick={handleForgotPassword}
                            >
                                Forgot My Password
                            </Button>
                        )}
                        <Button
                            className="next-btn"
                            type="solid"
                            onClick={this._login}
                            disabled={lockout || !this.state.password || isAuthenticating}
                        >
                            Sign In
                        </Button>
                    </div>
                )}
                {ssoEnabled && (
                    <div className="sso-row">
                        <span className="login-with-text">OR LOGIN WITH</span>
                        <Button
                            className="oauth"
                            type="line"
                            icon="/assets/images/Global/logos/icn-google.svg"
                            onClick={() => auth('google')}
                        />
                        <Button
                            className="oauth"
                            type="line"
                            icon="/assets/images/Global/logos/icn-microsoft.svg"
                            onClick={() => auth('outlook')}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default LoginView;
