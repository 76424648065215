import React from 'react';
import { Button } from '@btag/bt-ui-library';

const ResetAnswerView = ({handleBackToLogin}) => (
        <div className="reset-answer-view">
            <h3 className="login-header">
                Email sent!
            </h3>
            <div className="login-confirm-text">
                Follow the instructions on your email to reset your account password.
            </div>
            <Button className="back-login-btn" type="solid"
                    onClick={handleBackToLogin}>
                Login
            </Button>
        </div>
);

export default ResetAnswerView;