import config from './config';

const envmapper = (msRedirectUrl) => {
    if (msRedirectUrl.includes("sandbox.bananatag")) {
        return "https://sandbox-service.bananatag.com/auth/success";
    } else if (msRedirectUrl.includes("localhost")) {
        return "http://localhost:3030/auth/success";
    } else if (msRedirectUrl.includes("beta")) {
        return "https://sandbox-service-beta.bananatag.com/auth/success";
    } else if (msRedirectUrl.includes("gamma")) {
        return "https://sandbox-service-gamma.bananatag.com/auth/success";
    } else if (msRedirectUrl.includes("delta")) {
        return "https://sandbox-service-delta.bananatag.com/auth/success";
    } else if (msRedirectUrl.includes("app-staging")) {
        return "https://app-service-staging.bananatag.com/auth/success";
    } else if (msRedirectUrl.includes("app.bananatag")) {
        return "https://app-service.bananatag.com/auth/success";
    } else if (msRedirectUrl.includes("banana.tag")) {
        return "http://banana.tag:3030/auth/success";
    } else {
        return `${config.MEMBER_SITE_BE_URL}/auth/success`;
    }
};

export default (origin) => {
    switch (origin) {
        case "iav3":
            return `${config.SERVICE_API_V3_URL}/auth/complete`;
        case "gm":
            return `${config.apiPublicHost}/connect/success/gmail`;
        case "ms":
            return envmapper;
        case "ig":
            return `${config.apiPublicHost}/connect/success`;
        default:
            return config.SERVICE_API_V3_URL;
    }
};
