import React from 'react';

const Maintenance = () => {
    return (
        <div className="maintenance">
            <img src="assets/images/Global/logo.svg" alt="logo" style={{ marginTop: 54 }} />
            <h2 className="maintenance-title">You caught us in the middle of improvements!</h2>
            <div className="main-wrapper">
                <img src="assets/images/Maintenance/Illustration-SystemMaintenance.svg" alt="maintenance" />
                <div className="maintenance-text">
                    <p>The app will be temporarily unavailable, as we do some standard maintenance. Thanks for your patience!</p>
                    <br />
                    <p>See you soon,</p>
                    <p>Bananatag Engineering.</p>
                </div>
            </div>
            <img className="maintenance-footer-logo" src="assets/images/Maintenance/Logo_Wordmark.svg" alt="watermark" />
        </div>
    );
}

export default Maintenance;
