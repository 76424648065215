import React from 'react';

const StaffbaseError = () => {
    return (
        <div className="maintenance">
            <img src="assets/images/Staffbase/bananatag-soon-to-be-logo.svg" alt="Bananatag soon to be Staffbase" style={{ marginTop: 54 }} />
            <h2 className="maintenance-title">We couldn't load your Bananatag account.</h2>
            <div className="main-wrapper">
                <div className="maintenance-text maintenance-text--small">
                    <p>Check with your administrator if any updates are necessary to access it, or <a href=" https://bananatag.com/support/">contact support</a>.</p>
                </div>
            </div>
        </div>
    );
}

export default StaffbaseError;
