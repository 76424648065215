// Stay ES5 which is required by Grunt.

const local = process.env.REACT_APP_ENV !== 'staging';
const env = window._env_ || process.env;

const devOrStaging = {
    local,
    env: (local) ? 'dev' : 'staging',
    hostDomain: (local) ? 'http://localhost:8000' : 'https://login-staging.bananatag.com',
    apiPublicHost: (local) ? 'http://localhost:8080' : 'https://login-service-staging.bananatag.com',
    apiPort: (local) ? 8080 : 3030,
    app: {
        title: 'Bananatag Member Site'
    },
    featureFlags: {
        datadogEnabled: true,
    },
    datadogToken: 'pub8931ae85e874ab11161658822d6d3ab0',
    isProduction: false,
    launchDarkly: '5f5936e52c407509cdd27a06',
    SERVICE_API_V3_URL: 'https://service-staging.bananatag.com',
    MEMBER_SITE_FE_URL: (local) ? 'http://localhost:3000' : 'https://app-staging.bananatag.com',
    MEMBER_SITE_BE_URL: (local) ? 'http://localhost:3030' : 'https://app-service-staging.bananatag.com',
};

module.exports = {
    staging: devOrStaging,
    development: devOrStaging,
    production: {
        local: false,
        env: 'production',
        hostDomain: 'https://login.bananatag.com',
        port: 8000,
        apiPublicHost: 'https://login-service.bananatag.com',
        apiPort: 3030,
        app: {
            title: 'Bananatag Member Site'
        },
        featureFlags: {
            datadogEnabled: true,
        },
        datadogToken: 'pub8931ae85e874ab11161658822d6d3ab0',
        isProduction: true,
        launchDarkly: '5f5936e52c407509cdd27a07',
        SERVICE_API_V3_URL: 'https://serviceapi-v3-prod.bananatag.com',
        MEMBER_SITE_FE_URL: 'https://app.bananatag.com',
        MEMBER_SITE_BE_URL: 'https://app-service.bananatag.com'
    },
    dynamic: {
        local: env.LOCAL,
        env: env.ENVIRONMENT,
        hostDomain: env.HOST_DOMAIN,
        port: 8000,
        apiPublicHost: env.API_PUBLIC_HOST,
        apiPort: 3030,
        app: {
            title: 'Bananatag Member Site'
        },
        featureFlags: {
            datadogEnabled: process.env.DATADOG_ENABLED === 'true',
        },
        datadogToken: process.env.DATADOG_TOKEN,
        isProduction: true,
        launchDarkly: env.LAUNCHDARKLY_CLIENT_ID,
        SERVICE_API_V3_URL: env.SERVICE_API_V3_URL,
        MEMBER_SITE_FE_URL: env.MEMBER_SITE_FE_URL,
        MEMBER_SITE_BE_URL: env.MEMBER_SITE_BE_URL
    }
}[process.env.TECHNICAL_DEBT_FREE_MODE === 'true'
    || process.env.REACT_APP_TECHNICAL_DEBT_FREE_MODE === "true"
        ? 'dynamic' : (process.env.REACT_APP_ENV || 'staging')];

