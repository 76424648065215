import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import Loader from './loader';

class LoginParent extends Component {
    render() {
        const { isAuthenticating } = this.props;
        const { staffbaseBranding } = this.props;

        return (
            <>
                {isAuthenticating ?
                    <Loader />
                    :
                    <div className="login">
                        <DocumentTitle title="Login" />
                        {
                            staffbaseBranding &&
                            <h1 className="login-header">Access to Staffbase Email</h1>

                        }
                        {
                            staffbaseBranding === false &&
                            <img className="logo" src="assets/images/Global/logo.svg" alt="Bananatag soon to be Staffbase" height={32} width={32} />

                        }
                        <div className="login-page">
                            {this.props.children}
                            {
                                staffbaseBranding === true &&
                                <div className="footer-sb">
                                    <div className="info-container">
                                        <div className="footer-info">
                                            <h1>Enhance your comms with Staffbase Intranet</h1>
                                            <p>Ready to unlock the transformative power of integrated communications? Help your emails pack a bigger punch and give your content a permanent home with Staffbase Intranet!</p>
                                            <hr className="line" />
                                            <a href="https://staffbase.com/en/intranet/?utm_campaign=24q1emaillogin&utm_source=staffbase%20email%20login%20page">LEARN MORE &#8594;</a>
                                            <img src="assets/images/Staffbase/staffbase-email-loginpage.png" alt="Staffbase Intranet"></img>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default LoginParent;