import React from 'react';
import ReactDOM from 'react-dom';
import './config/polyfills';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import config from './config';
import { datadogLogs } from '@datadog/browser-logs'

if (config.featureFlags.datadogEnabled) {
    datadogLogs.init({
        clientToken: config.datadogToken,
        forwardErrorsToLogs: true,
        sampleRate: 100,
        service: 'auth-app'
    });
}

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
