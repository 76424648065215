import React, { Component } from 'react';
import LoginParent from './components/loginParent';
import LoginView from './components/loginView';
import ResetAnswerView from './components/resetAnswerView';
import ResetView from './components/resetView';
import session from './shared/session';
import config from './config';
import _get from 'lodash/get';
import moment from 'moment';
import { withLDConsumer, withLDProvider } from 'launchdarkly-react-client-sdk';

import authOriginMapper from './authOriginMapper'
import Maintenance from './components/Maintenance';
import StaffbaseError from './components/StaffbaseError';

import './App.scss';
import './client.scss';

import '@btag/bt-ui-library/Styles/normalize.scss';
import '@btag/bt-ui-library/Styles/global.scss';
import '@btag/bt-ui-library/Styles/grid.scss';
import '@btag/bt-ui-library/Styles/typography.scss';
import '@btag/bt-ui-library/Styles/animate.scss';
import '@btag/bt-ui-library/Styles/icons.scss';

import axios from 'axios';

class App extends Component {
    constructor() {
        super();
        this.state = {
            view: 'login',
            emailEntered: false,
            email: '',
            password: '',
            isAuthenticating: true,
            errorLogin: null,
            lockout: false,
            hasLogin: false,
            errorResetRequest: null,
            errorMsg: null,
            isRedirecting: false,
            loginProxyMsg: null,
            isStaffbaseAccount: false,
            failedStaffbaseLogin: false,
        };
        this.redirectTo = '/'  //this.props.location.query.next;
    }

    componentWillMount() {
        const authOrigin = this._queryParam('authOrigin');
        const email = this._queryParam('email');
        if (authOrigin === 'gi') {
            this._initConnectSync(email, 'gmail');
        } else if (authOrigin === 'o365') {
            this._initConnectSync(email, 'outlook365');
        }
    }

    componentDidMount() {
        this.setState({ emailEntered: false });
        if (this.state.hasLogin) {
            //TODO: redirect to /
            window.location = `${config.hostDomain}/`;
        }
        const failedStaffbaseLogin = window.location.href.includes('staffbase');

        if(failedStaffbaseLogin){
            this.setState({
                failedStaffbaseLogin: true,
                isAuthenticating: false,
                errorLogin: null,
                errorResetRequest: null
            });
        }

        const closeWindow = window.location.href.includes('closewindow');
        if (closeWindow) {
            window.close();
        }
        const ssoHashToken = window.location.hash;
        if (ssoHashToken) {
            const accessToken = ssoHashToken.split('&')[0].split('#access_token=')[1];
            this._tokenVerify(accessToken);
            this.setState({
                isAuthenticating: true,
                errorLogin: null,
                errorResetRequest: null
            });
        } else {
            this.setState({
                isAuthenticating: false
            });
        }
    }

    componentWillUnmount() {
        this.authInterval && clearInterval(this.authInterval);
    }

    _initConnectSync = async (email, type) => {
        try {
            const connectResponse = await axios({
                method: 'post',
                url: `${config.apiPublicHost}/connect/${type}/sync`,
                data: {
                    email
                }
            });
            const { data } = connectResponse;
            if (data.status === 'Fail') {
                //handle fail
                this._loginFail('Unable to generate connect Url.');
            } else {
                window.location.replace(data.data);
            }
        } catch (e) {
            this._loginFail('Unable to generate connect Url.');
        }
    }

    _tokenVerify = (token) => {
        try {
            const msRedirectUrl = window.sessionStorage.getItem('btMSRedirectUrl');
            const authOrigin = window.sessionStorage.getItem('btAuthOrigin');
            const email = window.sessionStorage.getItem('btUserInputEmail');
            window.sessionStorage.removeItem('btMSRedirectUrl');
            window.sessionStorage.removeItem('btAuthOrigin');
            window.sessionStorage.removeItem('btUserInputEmail');

            console.log('the location for origin is: ', authOrigin);

            const successUrl = msRedirectUrl ? authOriginMapper(authOrigin)(msRedirectUrl) : authOriginMapper(authOrigin);
            window.location = msRedirectUrl ? `${successUrl}/${token}/${encodeURIComponent(msRedirectUrl)}` : `${successUrl}?token=${token}`;
        } catch (e) {
            this._loginFail('You don\'t seem to have enough permissions to use Bananatag. Please contact your organization account admin to enable Bananatag for your account.');
        }
    }

    _queryParam = (paramKey) => {
        const query = window.location.search.substring(1);
        const queryParams = query.split("&") || [];
        const paramObj = {};
        queryParams.forEach(param => {
            paramObj[param.split("=")[0]] = param.split("=")[1];
        });
        return paramObj[paramKey];
    }

    _queryEmail = () => (this._queryParam('email') || null)

    _queryOrigin = () => (this._queryParam('authOrigin') || 'ms')

    _queryMSRedirectUrl = () => (this._queryParam('msRedirectUrl') || `${config.MEMBER_SITE_FE_URL}/`)

    _queryUUID = () => (this._queryParam('uuid') || '')

    _loginFail = (verbiage, lockout) => {
        this.setState({
            isAuthenticating: false,
            errorLogin: verbiage,
            lockout: lockout
        });
    }

    _validateEmailAndCheckIdPDomain = async (email, domain) => {
        this.setState({ isAuthenticating: true });
        const { flags } = this.props;
        let isStaffLogin, userExistsInDB, staffbaseAccount, ssoEnabledAccount;
        const isSSOTestUser = ['sso-tester+1@btag-sso-test-domain.ca', 'user@test-sso-staging.com', 'user@test-sso-production.com'].some(elm => elm.includes(email.split('@')[1])); // for these two users we want the sso normal flow to happen even with staff ip login (no sso login proxy)
        try {
            const checkIpData = await axios({
                method: 'get',
                url: `${config.apiPublicHost}/user/check/ip`
            });
            isStaffLogin = checkIpData.data.data;

            const userExistsInDBData = await axios({
                method: 'get',
                url: `${config.apiPublicHost}/user/email/${encodeURIComponent(email)}`
            });

            userExistsInDB = userExistsInDBData?.data?.data?.userExists ? JSON.parse(userExistsInDBData.data.data.userExists) : false;
            staffbaseAccount = userExistsInDBData?.data?.data?.staffbaseAccount ? JSON.parse(userExistsInDBData.data.data.staffbaseAccount) : false;
            ssoEnabledAccount = userExistsInDBData?.data?.data?.ssoEnabledAccount ? JSON.parse(userExistsInDBData.data.data.ssoEnabledAccount) : false;

            const idpDomainData = await axios({
                method: 'post',
                url: `${config.apiPublicHost}/sso/idp`,
                data: {
                    idpName: domain.trim().toLowerCase(),
                }
            });

            const { id } = idpDomainData.data.data;
            const isSSOLogin = id !== null || staffbaseAccount;

            if (isStaffLogin && isSSOLogin && ssoEnabledAccount && !isSSOTestUser && !staffbaseAccount) {
                this.setState({
                    isAuthenticating: false,
                    emailEntered: true,
                    errorLogin: userExistsInDB.statususerExistsInDB === false ? 'Couldn\'t find your Bananatag Account' : null,
                    loginProxyMsg: 'SSO login proxy (staff IP detected)'
                });
            } else if (!isSSOLogin) {
                this.setState({
                    isAuthenticating: false,
                    emailEntered: userExistsInDB === false ? false : true,
                    errorLogin: userExistsInDB === false ? 'Couldn\'t find your Bananatag Account' : null,
                    loginProxyMsg: null
                });
            } else {
                const authOrigin = this._queryOrigin();
                window.sessionStorage.setItem('btAuthOrigin', authOrigin);
                window.sessionStorage.setItem('btUserInputEmail', email);

                if (['iav3'].includes(authOrigin)) {
                    this.setState({ isRedirecting: true });
                    const popupW = window.open(`${config.apiPublicHost}/sso/spinit/${id}`, 'name', 'width=650px,height=550px,left=100px,top=100px,status=no,toolbar=no');
                    this.authInterval = setInterval(() => {
                        const urlStr = _get(popupW, 'location.href', '');
                        const urlHash = _get(popupW, 'location.hash', '');

                        if (urlStr.includes(`${config.hostDomain}/#access_token=`)) {
                            const accessToken = urlHash.split('&')[0].split('#access_token=')[1];
                            this._tokenVerify(accessToken);
                            clearInterval(this.authInterval);
                        }
                        if (urlStr.includes('error?message=') || urlStr.includes('bananatag.com/login')) {
                            this._loginFail('Unable to retrieve your account, please check you have the right email or contact Bananatag support.', false);
                            popupW.close();
                            clearInterval(this.authInterval);
                        }
                    }, 100);
                } else if (staffbaseAccount) {
                    window.sessionStorage.setItem('btMSRedirectUrl', this._queryMSRedirectUrl());
                    if(window.localStorage.getItem('sbRedirect') === "true"){
                        return window.location = `${config.apiPublicHost}/sso/staffbase_redirect?email=${encodeURIComponent(email)}`
                    } else {
                        this.setState({ isRedirecting: true, isStaffbaseAccount: true });
                    }
                } else {
                    window.sessionStorage.setItem('btMSRedirectUrl', this._queryMSRedirectUrl());
                    return window.location = `${config.apiPublicHost}/sso/spinit/${id}`
                }
            }
        } catch (err) {
            this._loginFail(err);
            this.setState({ emailEntered: false });
        }

        return null;
    }

    _login = async (email, password) => {
        try {
            session.setEmail(email);
        } catch (e) {
            this._loginFail('This site requires localStorage, if you are on Safari in incognito mode, please exit incognito mode.');
            return;
        }

        this.setState({
            isAuthenticating: true,
            email
        })
        //  dispatch so that the login button does not remain disabled forever if server is unresponsive.
        setTimeout(() => { this.setState({ isAuthenticating: false }) }, 4000);

        if (this.redirectTo === '/login') {
            this.redirectTo = '/';
        }
        try {
            const authOrigin = this._queryOrigin();
            const isOriginMembersite = authOrigin === 'ms';
            const msRedirectUrl = this._queryMSRedirectUrl();
            const data = {
                username: email,
                password,
                clientSource: authOrigin
            };
            if (isOriginMembersite) {
                data.msRedirectUrl = msRedirectUrl;
            }
            const result = await axios({
                method: 'post',
                url: `${config.apiPublicHost}/user/login`,
                data
            });

            if (_get(result, 'data.status') === 'Fail') {
                if (result.data.lockTime > 0) {
                    setTimeout(() => {
                        this.setState({
                            lockout: false,
                            errorLogin: null
                        });
                    }, result.data.lockTime * 1000);

                    this._loginFail(`Too many failed login attempts: Please try again in ${result.data.lockTime} seconds.`, true);
                } else {
                    this._loginFail(result.data.verbiage, false);
                }
            } else {
                const data = _get(result, 'data.data.data');
                this.setState({
                    errorLogin: null,
                    lockout: false
                })
                const redirect = isOriginMembersite ? `${data.successUrl}/${data.token}/${encodeURIComponent(msRedirectUrl)}` : `${data.successUrl}?token=${data.token}`;
                window.location = redirect;
            }
        } catch (res) {
            this._loginFail('Server issue');
        }
    }

    /**
     * Displays the forgot password form
     * @method _handleForgotPassword
     **/
    _handleForgotPassword = () => {
        this.setState({ view: 'reset' });
    }

    /**
     * Triggers an API call
     * @method _handleResetPassword
     **/
    _handleResetPassword = async (email) => {
        const valid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
        if (valid) {
            try {
                const response = await axios({
                    method: 'post',
                    url: `${config.apiPublicHost}/user/password/reset/request`,
                    data: {
                        username: email
                    }
                });
                if (response.data.status === 'Fail') {
                    this.setState({ errorResetRequest: response.data.verbiage });
                } else {
                    this.setState({ view: 'reset-answer-success' });
                }
            } catch (error) {
                if (error && error.status === 'Fail') {
                    this.setState({ errorResetRequest: error.verbiage });
                }
            }
        } else {
            this.setState({ errorResetRequest: 'Invalid email' });
        }
    }

    /**
     * Displays the login form if the user decides not to request a password reset
     * @method _handleCancel
     **/
    _handleCancel = () => {
        this._handleBackToLogin();
    }

    /**
     * Displays the login form after the email with reset link was sent
     * @method _handleBackToLogin
     **/
    _handleBackToLogin = () => {
        this.setState({
            errorLogin: null,
            errorResetRequest: null,
            view: 'login'
        })
    }

    _handleEmailEnter = (e) => {
        if (e.key === 'Enter') {
            this._handleEmailSubmit();
        }
    }

    _handleEmailSubmit = (email) => {
        const valid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
        if (valid) {
            this.setState({ email, errorMsg: null });
            const idpDomain = email.toLocaleLowerCase().substring(email.indexOf('@') + 1);
            this._validateEmailAndCheckIdPDomain(email, idpDomain);
        } else {
            this.setState({ errorMsg: 'Invalid email' });
        }
    }

    _auth = (provider) => {
        const apiURL = config.apiPublicHost;
        this.setState({ isAuthenticating: true });
        this._handleBackToLogin();
        const authOrigin = this._queryOrigin();
        if (['iav3'].includes(authOrigin)) {
            const popupW = window.open(`${apiURL}/auth/${provider}/${authOrigin}`, 'name', 'width=650px,height=550px,left=100px,top=100px,status=no,toolbar=no');
            this.authInterval = setInterval(() => {
                const urlStr = _get(popupW, 'location.href', '');
                if (urlStr.includes(`${config.hostDomain}/?successRedirect=`)) {
                    window.location = urlStr.split('successRedirect=')[1];
                    popupW.close();
                }
                if (urlStr.includes('error?message=') || urlStr.includes('bananatag.com/login')) {
                    popupW.close();
                    this._loginFail('Unable to retrieve your account, please check you have the right email or contact Bananatag support.', false)
                }
            }, 1000);
        } else {
            const msRedirectUrl = authOrigin === 'ms' ? encodeURIComponent(this._queryMSRedirectUrl()) : '';
            window.location.href = `${apiURL}/auth/${provider}/${authOrigin}/${msRedirectUrl}`;
        }
    }

    _resetEmail = () => {
        this.setState({
            emailEntered: false,
            loginProxyMsg: null,
            errorLogin: null
        });
    }

    _ssoEnabled = () => {
        return !this.state.emailEntered && this._queryOrigin() !== 'ig'; // gmail integration isn't prepared for SSO login
    }

    render() {
        const {
                errorMsg,
                isAuthenticating,
                errorResetRequest,
                lockout,
                email,
                password,
                errorLogin,
                isRedirecting,
                loginProxyMsg,
                isStaffbaseAccount,
                failedStaffbaseLogin
            } = this.state;
        const { flags } = this.props;

        const viewDOM = {
            "reset": <ResetView
                errorMsgResetRequest={errorResetRequest}
                errorMsg={this._errorMsg}
                email={email}
                handleCancel={this._handleCancel}
                handleResetPassword={this._handleResetPassword}
                staffbaseBranding={ flags.wa338 }
            />,
            "reset-answer-success": <ResetAnswerView
                handleBackToLogin={this._handleBackToLogin}
            />
        };

        if (flags && flags.datalayer57) {
            return <Maintenance />
        }

        if(failedStaffbaseLogin) {
            return <StaffbaseError />
        }
        return (
            <LoginParent isAuthenticating={isRedirecting && !isStaffbaseAccount} isStaffbaseAccount={isStaffbaseAccount} staffbaseBranding={ flags.wa338 }>
                <div className="login-form">
                    {viewDOM[this.state.view] ||
                    <LoginView
                        errorMsgLogin={errorLogin}
                        errorMsg={errorMsg}
                        resetEmail={this._resetEmail}
                        handleEmailEnter={this._handleEmailEnter}
                        handleEmailSubmit={this._handleEmailSubmit}
                        auth={this._auth}
                        handleForgotPassword={this._handleForgotPassword}
                        isAuthenticating={isAuthenticating}
                        lockout={lockout}
                        email={email}
                        login={this._login}
                        password={password}
                        emailEntered={this.state.emailEntered}
                        isRedirecting={isRedirecting}
                        isStaffbaseAccount={isStaffbaseAccount}
                        loginProxyMsg={loginProxyMsg}
                        ssoEnabled={this._ssoEnabled()}
                        staffbaseBranding={ flags.wa338 }
                    />}
                </div>
            </LoginParent>
        );
    }

}

export default withLDProvider({
    clientSideID: config.launchDarkly,
    user: {
        key: '0',
    },
})(withLDConsumer()(App));
