class BTSession {
    constructor() {
        this._email = localStorage.getItem('email') || null;
        this._token = localStorage.getItem('token') || null;
        this._parentToken = localStorage.getItem('parentToken') || null;
        this._parentEmail = localStorage.getItem('parentEmail') || null;
    }

    getEmail(getParent = false) {
        if (getParent) {
            return this._parentEmail;
        }

        return this._email;
    }

    isParent() {
        return this._parentToken !== null;
    }

    clearParent() {
        this._parentToken = null;
        this._parentEmail = null;
        localStorage.removeItem('parentToken');
        localStorage.removeItem('parentEmail');
    }

    setEmail(email, setParent = false) {
        if (typeof email === 'string' && email.length > 0) {
            if (setParent) {
                this._parentEmail = email;
                localStorage.setItem('parentEmail', email);
            } else {
                this._email = email;
                localStorage.setItem('email', email);
            }
        }
    }

    getToken(getParent = false) {
        if (getParent) {
            return this._parentToken;
        }

        return this._token;
    }

    setToken(token, setParent = false) {
        if (typeof token === 'string' && token.length > 0) {
            if (setParent) {
                this._parentToken = token;
                localStorage.setItem('parentToken', token);
            } else {
                this._token = token;
                localStorage.setItem('token', token);
            }
        }
    }

    hasToken() {
        return this._token !== null;
    }

    clearToken() {
        this._token = null;
        this._email = null;
        this._parentToken = null;
        this._parentEmail = null;
        localStorage.removeItem('token');
        localStorage.removeItem('email');
        localStorage.removeItem('parentToken');
        localStorage.removeItem('parentEmail');
    }

    isStaging() {
        return process.env.REACT_APP_ENV === 'staging';
    }
}

export default new BTSession();